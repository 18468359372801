<template>
	<main>
		<div class="container">
			<HomePAX />
		</div>
	</main>
</template>

<script type="text/javascript">

	export default {
		name: 'PaxHome',
		components: {
			HomePAX: () => import('@/components/Pax/Home'),
		}
	}

</script>